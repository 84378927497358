const normalizerRoute = [
    'index',
    'classes.topics.questions.add',
    'questions.normalisation',
    'account.questions',
    'account.document',
    'account.wallet',
    'setttings.fees'
]

export default normalizerRoute;