const approvalRoute = [
    'index',
    'questions.reviews',
    'questions.reviews.overview',
    'questions.normalisation',
    'officers.profile',
    'mainmenu.users',
    'mainmenu.users.guardians',
    'mainmenu.users.students',
    'users.profile',
    'user.profile',
    'action.bank.verification',
    'action.document.verification',
    'action.attribute.verification',
    'action.interest.verification',
    'account.wallet',
    'account.questions',
    'setttings.fees'
]

export default approvalRoute;