const reviewerRoute = [
    'index',
    'question',
    'user.profile',
    'questions.reviews',
    'questions.reviews.overview',
    'account.document',
    'account.wallet',
    'setttings.fees'
]

export default reviewerRoute