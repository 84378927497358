
const roleMap = {
  1 : "Student",  
  2 : "Guardian",
  4: "Tutor",
  8: "Official",
  16: "Support",
  32 : "Admin",
  64: "Super Admin",
  128: "System",
  256: "Master"
}

const roles = [
  
  // {label:'Student' , code: 1 },
  // {label:'Guardian' , code: 2 },
  {label:'Tutor' , code: 4 },
  {label:'Offical' , code: 8 },
  {label:'Support' , code: 16 },
  {label:'Admin' , code: 32 },
  {label:'Super Admin' , code: 64 },
  // {label:'System' , code: 128 },
  // {label:'Master' , code: 256 },
  
]
const roleAttributes = [
  {
    name: 'Teacher',
    code: 1,
    description: 'This is required for adding or uploading (PDF) questions.'
  },
  {
    name: 'Normalizer',
    code: 2,
    description: 'This is required for normalising uploaded questions.'
  },
  {
    name: 'Review',
    code: 4,
    description: 'This is required for reviewing submitted questions.'
  },
  {
    name: 'Approval',
    code: 8,
    description: 'This is required for approving questions and documents.'
  },
  {
    name: 'Finance',
    code: 16,
    description: 'This is required for financial related activities.'
  },
  {
    name: 'Agent',
    code: 32,
    description:'This is reserved.'
  }
]
const roleAttributesMap = {
  "1" : "Teacher", 
  "2" : "Normalizer", 
  "4" : "Reviewer", 
  "8" : "Approval", 
  "16" : "Finance", 
  "32" : "Agent", 
}

const progress = {
  "-1": "Diaspprove",
  "0": "Pending",
  "1": "Approved"
}
const progressColour = {
  "-1": "text-danger",
  "0": "text-info",
  "1": "text-success"
}
const tagBgColor = {
  "-1": "tag-danger",
  "0": "tag-warning",
  "1": "tag-success"
}
const progressIcon = {
  "-1": "las la-times",
  "0": "las la-pause-circle",
  "1": "las la-check"
}

const questionTypeMap = {
  "1": "Dichotomous",
  "2": "Simple",
  "4": "Multi",
  "8": "Rank",
  "16": "Open"
}

const questionBuildContraint = {
  1: {
    correctAnswer: '==1',
    options:'==2',
    solution: true,
  },
  2: {
    correctAnswer: '==1',
    options:'>3, <6',
    solution: true,
  },
  4: {
    correctAnswer: '>1,<6',
    options:'>3, <6',
    solution: true,
  },
  8: {
    correctAnswer: '>3',
    options:'>3',
    solution: true,
  },
  16: {
    correctAnswer: '==1',
    solution: true,
  },
}

const avatarColors = {
  '1': '#6B4E71',
  '2' : '#3A4454',
  '3' : '#53687E',
  '4' : '#F5DDDD',
  '5' : '#493843',
  '6' : '#61988E',
  '7' : '#A0B2A6',
  '8' : '#A89B8C',
  '9' : '#F38375',
  '10': '#EF6351',
  '11': '#44633F',
  '12': '#3F4B3B',
  '13': '#6A041D',
  '14': '#41292C',
}

export {
  roleMap,
  roles,
  roleAttributes,
  roleAttributesMap,
  questionTypeMap,
  progress,
  progressColour,
  progressIcon,
  avatarColors,
  questionBuildContraint,
  tagBgColor
}