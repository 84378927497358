const agentRoute = [
    'index',
    'classes.primary',
    'classes.junior',
    'classes.senior',
    'classes.topics',
    'classes.topics.questions',
    'classes.topics.questions.add',
    'questions.reviews',
    'account.questions',
    'user.profile',
    'questions',
    'questions.reviews.overview',
    'questions.upload',
    'questions.normalisation',
    'account.document',
    'account.wallet',
    'setttings.fees'
]

export default agentRoute;